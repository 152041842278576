import React, { useEffect, useRef } from "react";
import close_modal from "../../assets/img/close-modal.svg";

export default function CurrentIssueModal({ modalOpen, setModalOpen }) {
  const loginModalRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        modalOpen &&
        loginModalRef.current &&
        !loginModalRef.current.contains(event.target)
      ) {
        setModalOpen(false);
      }
    }

    if (modalOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalOpen, setModalOpen]);

  return (
    <div
      className={`modal_popup ${modalOpen ? "opacity-100 " : "opacity-0"}`}
      style={{ display: modalOpen ? "flex" : "none" }}
    >
      <div className="mx-auto sm:p-14 p-2 sm:mt-2 my-auto  md:!min-w-[550px] min-w-[70vw] ">
        <div
          ref={loginModalRef}
          className="bg-white rounded-md sm:py-5 sm:px-10 py-5 px-5 relative"
        >
          <div className="mb-2 text-end absolute -right-4 -top-4">
            <button
              className="md:px-2 md:py-2 sm:w-8 sm:h-8 px-2 py-2 w-8 h-8 bg-black rounded-full"
              onClick={() => setModalOpen(false)}
            >
              <img src={close_modal} alt="Close" />
            </button>
          </div>
          <div className="flex justify-center">
            <p className="md:text-[16px] sm:text-[13px] text-[12px] text-black text-center font-bold mb-5 uppercase">
              Choose Subscription Type
            </p>
          </div>
          <div className="flex justify-center flex-col sm:flex-row gap-5 my-2">
            <a
              href=" https://amg.biz/publications/asian-trader/"
              target="_blank"
              className=" px-4 py-2 inline-block bg-red text-white text-center hover:bg-black text-[10px] sm:text-[13px] uppercase"
            >
              Digital Subscription
            </a>

            <a
              href="https://asiantrader.biz/subscriptions/"
              target="_blank"
              className="px-4 py-2 bg-red text-white inline-block text-center hover:bg-black text-[10px] sm:text-[13px] uppercase"
            >
              Print Subscription
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
