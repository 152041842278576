import CurrentIssue from "../../Component/Third-PartyAds/CurrentIssue";
import NewsLetter from "../../Component/Third-PartyAds/NewsLetter";
// import Group_215 from "../../assets/img/Group 215.png";
import { useStaticPageData } from "../../Middleware/useStaticPageData";
import parse from "html-react-parser";
import Loader from "../../Component/UI/Loader";
import useFetchMetaTags from "../../Middleware/useFetchMetaTags";
// import { Helmet } from "react-helmet-async";

const AboutUs = () => {
  const { data: about, isLoading } = useStaticPageData("about-us");
  // const { data: tags } = useFetchMetaTags("about-us");
  const content =
    typeof about?.data?.about_us_content === "string"
      ? about?.data?.about_us_content
      : "";
  return (
    <>
      {/* <Helmet>
        <title>{`${tags?.data?.title}`}</title>
        <meta name="description" content={`${tags?.data?.description}`} />
        <link rel="canonical" href={`${tags?.data?.canonical}`} />
        <meta property="og:locale" content={`${tags?.data?.og_locale}`} />
        <meta property="og:type" content={`${tags?.data?.og_type}`} />
        <meta property="og:title" content={`${tags?.data?.og_title}`} />
        <meta
          property="og:description"
          content={`${tags?.data?.og_description}`}
        />
        <meta property="og:url" content={`${tags?.data?.og_url}`} />
        <meta property="og:site_name" content={`${tags?.data?.og_site_name}`} />
        <meta
          property="og:image"
          content={`https://admin.asiantrader.biz/wp-content/uploads/sites/1/2024/09/AT-logo-White-3-1.png`}
        />
        <meta
          property="og:updated_time"
          content={`${tags?.data?.og_updated_time}`}
        />
        <meta
          property="article:published_time"
          content={`${tags?.data?.article_published_time}`}
        />
        <meta
          property="article:modified_time"
          content={`${tags?.data?.article_modified_time}`}
        />
        <meta name="twitter:card" content={`${tags?.data?.twitter_card}`} />
        <meta name="twitter:title" content={`${tags?.data?.twitter_title}`} />
        <meta
          name="twitter:description"
          content={`${tags?.data?.twitter_description}`}
        />
      </Helmet> */}
      <section className="pb-[50px] product bg-blur">
        {isLoading ? (
          <Loader />
        ) : (
          <div className="container mx-auto px-7 relative">
            <div className="pb-2 text-2xl text-[#231E1E] font-bold border-b border-[#d4d4d4] mb-5 lg:pl-4">
              ABOUT US
            </div>
            <div className="grid grid-cols-12 gap-5">
              <div className="lg:col-span-9 md:col-span-8 col-span-12">
                <div className="mb-10">
                  <img
                    src={about?.data?.about_us_image}
                    alt="Group 215"
                    className="w-full sm:min-h-72 max-h-72 min-h-52 object-right object-cover rounded-[10px]"
                  />
                </div>
                <div className="lg:pl-4 lg:pr-10 pr-6 about-us">
                  {parse(content)}
                </div>
              </div>
              <div className="lg:col-span-3 md:col-span-4 col-span-12">
                <div className="mb-[51px] bg-[#231E1E] p-5 rounded-[10px]">
                  <CurrentIssue />
                </div>
                <div className="">
                  <NewsLetter />
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default AboutUs;
