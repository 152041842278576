import { useContext, useState } from "react";
// import AT_938 from "../../assets/img/AT-938.png";
import { DataContext } from "../Context/SidebarContext";
import { Link } from "react-router-dom";
import CurrentIssueModal from "./CurrentIssueModal";

const CurrentIssue = () => {
  const { data } = useContext(DataContext);
  const [modalOpen, setModalOpen] = useState(false);
  const currentData = data?.data;

  return (
    <>
      <h2 className="text-[20px] text-white font-black">CURRENT ISSUE</h2>
      <div className="h-[10px] border-y-2 border-grey mb-5 mt-[10px]"></div>

      <div className="lg:mt-14 mt-8 lg:mb-14 mb-8">
        <a
          href={currentData?.digital_edition_post?.magazine_url}
          target="_blank"
        >
          <img
            src={currentData?.digital_edition_post?.post_image}
            alt="current issue"
            className="w-full object-cover h-auto"
          />
        </a>
      </div>
      <div className="mt-auto">
        <Link
          to={currentData?.digital_archive?.url}
          target="_blank"
          className="border-2 inline-block text-center border-red text-white lg:py-3 py-2 text-sm rounded-md  mx-auto w-full font-black"
        >
          Digital Archive
        </Link>
        <button
          onClick={(e) => {
            e.stopPropagation();
            setModalOpen(true);
          }}
          // href={currentData?.subscribe?.url}
          // target="_blank"
          className="bg-red inline-block text-center text-white lg:py-3 py-2 rounded-md mx-auto text-[14px] w-full mt-[20px] font-black"
        >
          Subscribe
        </button>
      </div>
      <CurrentIssueModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
    </>
  );
};

export default CurrentIssue;
